import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { slugify } from "../utils/utilityFunctions"
import HeroImage from "../elements/heroImage"
import CallToAction from "../elements/calltoaction/calltoaction"
import Seo from "../components/seo"

const BlogDetails = ({ data, pageContext }) => {
  const {
    title,
    image,
    tags,
    titleSEO,
    descriptionSEO,
  } = data.markdownRemark.frontmatter
  const imageSrc = image.childImageSharp
  const { html } = data.markdownRemark

  const baseUrl = "https://www.seoalternative.fr"

  const seoTitle = titleSEO
  const seoDescription = descriptionSEO
  const BackgroundImage = imageSrc.fluid.src

  return (
    <Layout>
      <Seo title={seoTitle} description={seoDescription} />
      <div className="blog-details-wrapper pt--70 pb--100 bg-color-white">
        <div className="row">
          <div className="col-lg-12">
            <HeroImage
              description="my hero academia"
              path={BackgroundImage}
            />
            <div className="container" style={{marginTop: "50px"}}>
              <div className="post-single-content">
                <div className="post-single-title">
                  <h1 className="post-title">{title}</h1>
                </div>
                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                 <div className="tag-list d-flex align-items-center">
                  <span>Tags:</span>
                  <div className="tags-cloud">
                    {tags.map(tag => (
                      <a key={tag} href={`/tag/${slugify(tag)}`}>
                        {tag}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

         
        </div>

               
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-contact-form">
                  <div className="social-share-inner text-center pt--50">
                    <h3>Vous avez aimé cet article ? Partagez-le !</h3>
                    <ul className="social-share-links liststyle d-flex justify-content-center">
                      <li>
                        <a
                          className="facebook"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://www.facebook.com/sharer.php?u=" +
                            baseUrl +
                            pageContext.slug
                          }
                        >
                          <span>Facebook</span>
                        </a>
                      </li>

                      <li>
                        <a
                          className="twitter"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://www.twitter.com/share?url=" +
                            baseUrl +
                            pageContext.slug +
                            "&text=" +
                            title +
                            "&via" +
                            "twitterHandle"
                          }
                        >
                          <span>Twitter</span>
                        </a>
                      </li>

                    

                      <li>
                        <a
                          className="linkedin"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://www.linkedin.com/shareArticle?url=" +
                            baseUrl +
                            pageContext.slug
                          }
                        >
                          <span>LinkedIn</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <CallToAction backgroundColor="#0e0e0f"></CallToAction>
    </Layout>
  )
}

export const blogDetailsData = graphql`
  query blogDetailsQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        author {
          name
        }
        category
        title
        titleSEO
        descriptionSEO
        date(formatString: "MMM Do, YYYY")
        format
        tags
        image {
          childImageSharp {
            fluid(quality: 100, maxHeight: 350, maxWidth: 510) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default BlogDetails
